import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import HeroComponent from "./components/Hero";
import AboutComponent from "./components/About";
import ServiceComponent from "./components/Services";
import TeamComponent from "./components/Team";
import PartnerComponent from "./components/Partners";
import ContactComponent from "./components/Contact";
import FooterComponent from "./components/Footer";
import Achievement from "./components/Achievement";

function App() {
  const [theme, setTheme] = useState("dark"); // Set initial theme to dark

  useEffect(() => {
    // Apply theme on component mount
    if (localStorage.theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  const changeTheme = () => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (theme === "dark") {
      setTheme("light");
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    } else {
      setTheme("dark");
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    }
  };

  return (
    <div>
      <Navbar changeTheme={changeTheme} theme={theme} />
      <HeroComponent />
      <AboutComponent />
      <ServiceComponent />
      <TeamComponent />
      <PartnerComponent />
      <Achievement />
      <ContactComponent />
      <FooterComponent />
    </div>
  );
}

export default App;
