import React from "react";
import heroImg from "../assets/hero.png";
import { motion } from "framer-motion";
import { MdOutlineLocalPharmacy } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { PiPresentationChart } from "react-icons/pi";

const HeroComponent = () => {
  return (
    <motion.section
      id="home"
      className="min-h-screen flex items-center justify-center dark:bg-backgroundDark dark:text-darkText"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className="lg:grid max-w-screen-2xl lg:px-4 px-2 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-0 lg:grid-cols-12 flex flex-col-reverse gap-16 justify-center items-center"
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.div
          className="mx-auto place-self-center lg:col-span-6"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="font-primary max-w-2xl mb-4 text-2xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
            Seamless Pharmacy Connections
          </h1>
          <p className="font-primary max-w-2xl mb-6 font-normal text-gray-500 lg:mb-8 md:text-lg lg:text-2xl">
            Connecting Patients, Pharmacies, and Possibilities
          </p>
          <div className="flex gap-4">
            <a
              href="https://retailer.wadpharma.com/"
              target="_blank"
              className="font-primary flex gap-2 items-center justify-center max-w-2xl mb-6 font-normal bg-buttons dark:bg-teald lg:px-4 lg:py-2.5 px-2 py-2 shadow-2xl rounded-xl text-white lg:mb-8 md:text-lg lg:text-xl"
              rel="noreferrer"
            >
              <MdOutlineLocalPharmacy />
              <span className="lg:mt-1">Pharmacies</span>
            </a>
            <a
              href="https://wholesaler.wadpharma.com/"
              target="_blank"
              className="font-primary flex gap-2 items-center justify-center max-w-2xl mb-6 font-normal bg-buttons dark:bg-teald lg:px-4 lg:py-2.5 px-2 py-2 shadow-2xl rounded-xl text-white lg:mb-8 md:text-lg lg:text-xl"
              rel="noreferrer"
            >
              <TbTruckDelivery />
              <span className="lg:mt-1">Distributors</span>
            </a>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfhkgWKpEO5UwmY7ustomVGQY7bYJyvBCecGvbHrAYJNa6EcA/viewform"
              target="_blank"
              className="font-primary flex gap-2 items-center justify-center max-w-2xl mb-6 font-normal bg-buttons dark:bg-teald lg:px-4 lg:py-2.5 px-2 py-2 shadow-2xl rounded-xl text-white lg:mb-8 md:text-lg lg:text-xl"
              rel="noreferrer"
            >
              <PiPresentationChart />
              <span className="lg:mt-1">Book a Demo</span>
            </a>
          </div>
          {/* Video Player */}
          <div className="mt-6">
            <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/HCO0zHFawAk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="rounded-lg shadow-xl"
            ></iframe>
          </div>
          
        </motion.div>

        <motion.div
          id="imageElement"
          className="lg:mt-0 lg:col-span-6 lg:flex"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.img src={heroImg} alt="mockup" />
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

export default HeroComponent;
